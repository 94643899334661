







































import { Component, Prop } from 'vue-property-decorator';
import VueBaseWidget from '@/utils/widgets/VueBaseWidget';
import ExtraPropertiesTypes from '@/utils/enums/ExtraPropertiesTypes';
import ExtraPropertyElement from '@/components/extraPropertiesSection/ExtraPropertyElement.vue';
import ExtraPropertyElementColors
  from '@/components/extraPropertiesSection/ExtraPropertyElementColors.vue';
import ExtraProperty from '@/models/graphql/ExtraProperty';
import { mixins } from 'vue-class-component';
import VueRegisterStoreWidget from '@/utils/widgets/VueRegisterStoreWidget';
import WidgetHelper from '@/utils/helpers/widgets/WidgetHelper';
import { Location } from 'vue-router';
import { buildQueryDefinition } from '@/graphql/_Tools/GqlQueryDefinition';
import GqlEntityFilterType from '@/utils/enums/gql/GqlEntityFilterType';

@Component({
  components: {
    ExtraPropertyElement,
    ExtraPropertyElementColors,
  },
})
export default class ExtraPropertySectionWidget extends mixins(VueBaseWidget, VueRegisterStoreWidget) {
  private ExtraPropertiesType = ExtraPropertiesTypes;

  @Prop({ required: false, default: 2 })
  private readonly columns!: number;

  @Prop({ required: false, default: () => [] })
  private data!: ExtraProperty[];

  private get sectionName(): string {
    if (this.data[0]
      && this.data[0].config
      && this.data[0].config.section
      && this.data[0].config.section.name) {
      return this.data[0].config.section.name;
    }
    return '';
  }

  private get properties(): { label: string; value: string[] | string; type: string }[] {
    return this.data
      .sort((a, b) => (
        // eslint-disable-next-line no-nested-ternary
        a.config.displaySequence > b.config.displaySequence
          ? 1
          : a.config.displaySequence < b.config.displaySequence
            ? -1
            : 0))
      .map((ext) => ({
        value: (this.ExtraPropertiesType.COLOR === ext.config.valueType
          || this.ExtraPropertiesType.COLOR_LIST === ext.config.valueType)
          ? ext.value.split(/(?:\s*,\s*(?=#)|\s*,\s*(?=r)|\s*,\s*(?=h))+/)
          : ext.value,
        label: ext.config.name,
        type: ext.config.valueType,
      }));
  }

  created(): void {
    if (this.payload
      && 'entityCode' in this.payload
      && this.payload.entityCode
      && this.payload.section) {
      const entityCode = WidgetHelper
        .entityCode(this.payload.entityCode as string, this.$route as unknown as Location);
      this.setDataConfig([{
        alias: this.storeName,
        operation: 'extraProperty',
        gqlDefinition: buildQueryDefinition({
          cacheable: true,
          filter: {
            type: GqlEntityFilterType.EXTRA_PROPERTY_FILTER,
            value: {
              config: {
                section: {
                  id: this.payload.section,
                },
              },
              largeProduct: {
                uid: entityCode,
              },
            },
          },
        }),
        fragmentName: 'extraPropertyFullFragment',
      }]);
    } else if (this.widget) {
      this.setDataConfig(
        undefined,
        true,
      );
    }
  }

  mounted(): void {
    if (this.data && this.data.length > 0) {
      const extraPropertiesSectionElement = this.$el.querySelector('.extra-property-column');
      (extraPropertiesSectionElement as HTMLElement).style.gridTemplateColumns = `repeat(${this.columns}, 1fr)`;
    }
  }
}
